import React from "react";
import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export default function Categorize({
  viewBox = "0 0 10 10",
  width = 10,
  height = 10,
  fill = colors.white,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} viewBox={viewBox} {...iconProps} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84344 0.534246C4.94282 0.488585 5.05718 0.488585 5.15656 0.534246L9.78156 2.65925C9.91469 2.72041 10 2.8535 10 3C10 3.1465 9.91469 3.27959 9.78156 3.34075L5.15656 5.46575C5.05718 5.51142 4.94282 5.51142 4.84344 5.46575L0.218437 3.34075C0.0853146 3.27959 0 3.1465 0 3C0 2.8535 0.0853146 2.72041 0.218437 2.65925L4.84344 0.534246ZM1.2732 3L5 4.71231L8.7268 3L5 1.28769L1.2732 3Z"
        fill={fill}
      />
      <path
        d="M9.78156 4.65925C9.91469 4.72041 10 4.8535 10 5C10 5.1465 9.91469 5.27959 9.78156 5.34075L5.15656 7.46575C5.05718 7.51142 4.94282 7.51142 4.84344 7.46575L0.218437 5.34075C0.0853146 5.27959 0 5.1465 0 5C0 4.8535 0.0853146 4.72041 0.218437 4.65925C0.317805 4.61359 0.432154 4.61359 0.531522 4.65925L5 6.71231L9.46839 4.65925C9.56778 4.61358 9.68217 4.61358 9.78156 4.65925Z"
        fill={fill}
      />
      <path
        d="M9.78156 6.65925C9.91469 6.72042 10 6.8535 10 7.00001C10 7.14651 9.91469 7.27959 9.78156 7.34076L5.15656 9.46576C5.05718 9.51142 4.94282 9.51142 4.84344 9.46576L0.218437 7.34076C0.0853146 7.27959 0 7.14651 0 7.00001C0 6.8535 0.0853146 6.72042 0.218437 6.65925C0.317805 6.6136 0.432154 6.6136 0.531522 6.65925L5 8.71232L9.46839 6.65925C9.56778 6.61358 9.68217 6.61358 9.78156 6.65925Z"
        fill={fill}
      />
    </Icon>
  );
}
