import React from "react";
import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export default function Docs({ viewBox = "0 0 12 12", ...iconProps }: IconProps) {
  return (
    <Icon size={12} fill="none" {...iconProps} viewBox={viewBox}>
      <path
        stroke={colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.5"
        d="M3.25 10.75h5.5a1 1 0 001-1v-7.5a1 1 0 00-1-1h-5.5a1 1 0 00-1 1v7.5m1 1a1 1 0 01-1-1m1 1H5m-2.75-1a1 1 0 011-1h5.5a1 1 0 001-1V6.125M4.25 3.25h3.5m-3.5 2h2"
      ></path>
    </Icon>
  );
}
