import React from "react";
import Icon, { IconProps } from "./Icon";
import { colors } from "@puzzle/theme";

export default function Keyboard({ viewBox = "0 0 12 12", ...iconProps }: IconProps) {
  return (
    <Icon size={12} fill="none" {...iconProps} viewBox={viewBox}>
      <g clipPath="url(#clip0_175_700)">
        <path
          fill="#B3B2B8"
          d="M3.125 6.375a.25.25 0 11-.5 0 .25.25 0 01.5 0zM3.125 8.75a.25.25 0 11-.5 0 .25.25 0 01.5 0zM5.125 6.375a.25.25 0 11-.5 0 .25.25 0 01.5 0zM7.375 6.375a.25.25 0 11-.5 0 .25.25 0 01.5 0zM9.375 6.375a.25.25 0 11-.5 0 .25.25 0 01.5 0zM9.375 8.75a.25.25 0 11-.5 0 .25.25 0 01.5 0z"
        ></path>
        <path
          stroke={colors.white}
          strokeOpacity="0.5"
          strokeWidth="0.25"
          d="M2.875 6.75a.375.375 0 100-.75.375.375 0 000 .75zm0 2.375a.375.375 0 100-.75.375.375 0 000 .75zm2-2.375a.375.375 0 100-.75.375.375 0 000 .75zm2.25 0a.375.375 0 100-.75.375.375 0 000 .75zm2 0a.375.375 0 100-.75.375.375 0 000 .75zm0 2.375a.375.375 0 100-.75.375.375 0 000 .75z"
        ></path>
        <path
          stroke="#B3B2B8"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.75 8.75h2.5m-4.5-4.5v-1a1 1 0 011-1H8.5a.75.75 0 00.75-.75v-.25m-7.5 3h8.5a1 1 0 011 1v4.5a1 1 0 01-1 1h-8.5a1 1 0 01-1-1v-4.5a1 1 0 011-1z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_175_700">
          <path fill={colors.white} d="M0 0H12V12H0z"></path>
        </clipPath>
      </defs>
    </Icon>
  );
}
